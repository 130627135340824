import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface ITabloekraniDagilm_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface ITabloekraniDagilm_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	DBmagazaSelect: any[];
	DBmagazaSelect_dummy: any[];
	magazaVeriDoldur: any[];
	magazaVeriDoldur_dummy: any[];
	managerMagazaVeri: any[];
	managerMagazaVeri_dummy: any[];
	tabloicinVeriGetirSatis: any[];
	tabloicinVeriGetirSatis_dummy: any[];

isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
}

export class TabloekraniDagilm_ScreenBase extends React.PureComponent<ITabloekraniDagilm_ScreenProps, any> {
	tabloekranidagilm_984774_value_kuikaSelectBoxRef: React.RefObject<any>;
	tabloekranidagilm_749265_value_kuikaTableRef: React.RefObject<any>;
    ml=[{"Id":"c27bb49b-e84b-2132-b611-3806f3391b29","Name":"tr_TR","ShortName":"tr","IsDefault":true,"LanguagePhrases":[]},{"Id":"06d2c229-146e-4155-8c55-a1684b084d7f","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"d5070f1e-d28f-4f8a-b9a6-cef40ec3be76","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":426503,"PropertyName":"value","Value":"Mağaza :"},{"Id":984774,"PropertyName":"placeholder","Value":"Lütfen mağaza seçiniz.."},{"Id":749265,"PropertyName":"nodatafoundmessage","Value":"No data found."},{"Id":12509,"PropertyName":"title","Value":"Ürün Adı"},{"Id":341960,"PropertyName":"value","Value":"[datafield:urunadi]"},{"Id":644030,"PropertyName":"title","Value":"Tarih"},{"Id":460092,"PropertyName":"value","Value":"[datafield:uzuntarih]"},{"Id":891709,"PropertyName":"title","Value":"Adet"},{"Id":693131,"PropertyName":"value","Value":"[datafield:toplamadet]"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);
		this.tabloekranidagilm_984774_value_kuikaSelectBoxRef = React.createRef();
		this.tabloekranidagilm_749265_value_kuikaTableRef = React.createRef();

        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        DBmagazaSelect: [],
	        magazaVeriDoldur: [],
	        managerMagazaVeri: [],
	        tabloicinVeriGetirSatis: [],

            isMasterPageInitLoaded: false,
  isMasterPageRecurringLoaded: false,
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("tabloekranidagilm", "mainframe_screen");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (false){
            await this.tabloekraniDagilmPageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("tabloekranidagilm", "mainframe_screen");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("tabloekranidagilm", "mainframe_screen"    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.tabloekraniDagilmPageInit();
		}

        		if (prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded && this.state.isMasterPageInitLoaded === true) {await this.tabloekraniDagilmPageInit();}

	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

        setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  }

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  }

    tabloekraniDagilmPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

        let localVar = {
			Id_1: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "tabloekraniDagilm/tabloekraniDagilmPageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.DBmagazaSelect = result?.data.dBmagazaSelect;
		stateVars.magazaVeriDoldur = result?.data.magazaVeriDoldur;
		stateVars.managerMagazaVeri = result?.data.managerMagazaVeri;
		
		formVars.tabloekranidagilm_984774_options = stateVars.managerMagazaVeri;
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.tabloekraniDagilmPageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	tabloekraniDagilmPageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			
			stateVars.dataSource_984774 = this.state.managerMagazaVeri;
			stateVars.dataSource_984774 = this.state.managerMagazaVeri;

	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




    tabloekraniDagilmComponent_984774_onChange = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				Id_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "tabloekranidagilm_984774_value", "value", "managerMagazaVeri", "id", "id")), "Guid")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "tabloekraniDagilm/tabloekraniDagilmComponent_984774_onChange", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.tabloicinVeriGetirSatis = result?.data.tabloicinVeriGetirSatis;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.tabloekraniDagilmComponent_984774_onChange1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		tabloekraniDagilmComponent_984774_onChange1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				
				stateVars.dataSource_749265 = this.state.tabloicinVeriGetirSatis;

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }






    async callCallbackFunction(diId) {
        let isErrorOccurred = false;

    }
}
