import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface ILeftMenu_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface ILeftMenu_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	GoBack: any;
	GoBack_dummy: any;
	NAVIGATE: any;
	NAVIGATE_dummy: any;
	Logout: boolean;
	Logout_dummy: boolean;
	goToSignInActivity: any;
	goToSignInActivity_dummy: any;
	isCompleftmenu_856893AuthorizationVisible: 'visible' | 'hidden';
	isCompleftmenu_141288AuthorizationVisible: 'visible' | 'hidden';
	isCompleftmenu_743266AuthorizationVisible: 'visible' | 'hidden';
	isCompleftmenu_690153AuthorizationVisible: 'visible' | 'hidden';
	isCompleftmenu_138261AuthorizationVisible: 'visible' | 'hidden';
	isCompleftmenu_843198AuthorizationVisible: 'visible' | 'hidden';
	isCompleftmenu_37668AuthorizationVisible: 'visible' | 'hidden';
	isCompleftmenu_814444AuthorizationVisible: 'visible' | 'hidden';
	isCompleftmenu_689676AuthorizationVisible: 'visible' | 'hidden';
	isCompleftmenu_38654AuthorizationVisible: 'visible' | 'hidden';


}

export class LeftMenu_ScreenBase extends React.PureComponent<ILeftMenu_ScreenProps, any> {

    ml=[{"Id":"c27bb49b-e84b-2132-b611-3806f3391b29","Name":"tr_TR","ShortName":"tr","IsDefault":true,"LanguagePhrases":[]},{"Id":"06d2c229-146e-4155-8c55-a1684b084d7f","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"86e7bc40-0655-4aec-be76-9c62c4f9a757","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":501123,"PropertyName":"value","Value":"Menu"},{"Id":950642,"PropertyName":"value","Value":"Anasayfa"},{"Id":920157,"PropertyName":"value","Value":"Manager Analiz Ekranı"},{"Id":957674,"PropertyName":"value","Value":"Saha Ziyaret Formu"},{"Id":479315,"PropertyName":"value","Value":"Saha Personeli AnaSayfa"},{"Id":932220,"PropertyName":"value","Value":"Geçmiş Ziyaretler"},{"Id":985242,"PropertyName":"value","Value":"Migros veri giriş"},{"Id":827012,"PropertyName":"value","Value":"Mağaza görüntüle"},{"Id":580593,"PropertyName":"value","Value":"Ürün Satış Grubu"},{"Id":61250,"PropertyName":"value","Value":"Kur farkı bak"},{"Id":865755,"PropertyName":"value","Value":"Rota verileri"},{"Id":741437,"PropertyName":"value","Value":"Tanımlamalar"},{"Id":491582,"PropertyName":"value","Value":"Kullanıcı Tanımlama"},{"Id":188381,"PropertyName":"value","Value":"Çıkış"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);


        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        GoBack: "",
	        NAVIGATE: "",
	        Logout: false,
	        goToSignInActivity: "",
	        isCompleftmenu_856893AuthorizationVisible: 'visible',
	        isCompleftmenu_141288AuthorizationVisible: 'visible',
	        isCompleftmenu_743266AuthorizationVisible: 'visible',
	        isCompleftmenu_690153AuthorizationVisible: 'visible',
	        isCompleftmenu_138261AuthorizationVisible: 'visible',
	        isCompleftmenu_843198AuthorizationVisible: 'visible',
	        isCompleftmenu_37668AuthorizationVisible: 'visible',
	        isCompleftmenu_814444AuthorizationVisible: 'visible',
	        isCompleftmenu_689676AuthorizationVisible: 'visible',
	        isCompleftmenu_38654AuthorizationVisible: 'visible',

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("leftmenu", "");
            return;
        }
        
        
        if (true){
            
        }
        ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_856893AuthorizationVisible", ["2f1fb53b-d0eb-f20f-195b-0368c6884c30","35d35d5a-ccde-80e6-c61f-f71c537e94d9","Admin","Manager"]);
ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_141288AuthorizationVisible", ["35d35d5a-ccde-80e6-c61f-f71c537e94d9","2f1fb53b-d0eb-f20f-195b-0368c6884c30","Admin","Manager"]);
ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_743266AuthorizationVisible", ["35d35d5a-ccde-80e6-c61f-f71c537e94d9","Admin"]);
ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_690153AuthorizationVisible", ["35d35d5a-ccde-80e6-c61f-f71c537e94d9","2f1fb53b-d0eb-f20f-195b-0368c6884c30","Admin","Manager"]);
ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_138261AuthorizationVisible", ["35d35d5a-ccde-80e6-c61f-f71c537e94d9","2f1fb53b-d0eb-f20f-195b-0368c6884c30","Admin","Manager"]);
ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_843198AuthorizationVisible", ["35d35d5a-ccde-80e6-c61f-f71c537e94d9","2f1fb53b-d0eb-f20f-195b-0368c6884c30","Admin","Manager"]);
ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_37668AuthorizationVisible", ["35d35d5a-ccde-80e6-c61f-f71c537e94d9","2f1fb53b-d0eb-f20f-195b-0368c6884c30","Admin","Manager"]);
ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_814444AuthorizationVisible", ["35d35d5a-ccde-80e6-c61f-f71c537e94d9","2f1fb53b-d0eb-f20f-195b-0368c6884c30","Admin","Manager"]);
ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_689676AuthorizationVisible", ["35d35d5a-ccde-80e6-c61f-f71c537e94d9","2f1fb53b-d0eb-f20f-195b-0368c6884c30","Admin","Manager"]);
ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_38654AuthorizationVisible", ["35d35d5a-ccde-80e6-c61f-f71c537e94d9","2f1fb53b-d0eb-f20f-195b-0368c6884c30","Admin","Manager"]);

        
        
        KuikaAppManager.calculateAndSetBodyHeight("leftmenu", "");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("leftmenu", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			
		}

        
	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      


		LeftMenuComponent_620279_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		LeftMenuComponent_822360_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "LeftMenu", "HomePage", "", "currentPage", null, null, null, null, null, null, true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		LeftMenuComponent_90754_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "LeftMenu", "cevdetScreen", "", "currentPage", null, null, null, null, null, null, true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		LeftMenuComponent_770854_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("SahaZiyaretFormu", "verigetir", Guid.create().toString());

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "LeftMenu", "SahaZiyaretFormu", "", "currentPage", null, null, null, null, null, null, true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		LeftMenuComponent_297822_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "LeftMenu", "SahaGorevlisiMain", "", "currentPage", null, null, null, null, null, null, true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		LeftMenuComponent_856893_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "LeftMenu", "SahaZiyaretListesi", "", "currentPage", null, null, null, null, null, null, true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		LeftMenuComponent_141288_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "LeftMenu", "MigrosVeriGirisi", "", "currentPage", null, null, null, null, null, null, true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		LeftMenuComponent_743266_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "LeftMenu", "magazalts", "", "currentPage", null, null, null, null, null, null, true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		LeftMenuComponent_691185_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "LeftMenu", "UrunSatisGrupListe", "", "currentPage", null, null, null, null, null, null, true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		LeftMenuComponent_843198_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "LeftMenu", "kur", "", "currentPage", null, null, null, null, null, null, true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		LeftMenuComponent_37668_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "LeftMenu", "RutYT", "", "currentPage", null, null, null, null, null, null, true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		LeftMenuComponent_689676_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "LeftMenu", "KullaniciListe", "", "currentPage", null, null, null, null, null, null, true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		LeftMenuComponent_20424_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.Logout = await ReactSystemFunctions.logout();
				stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    async callCallbackFunction(diId) {
        let isErrorOccurred = false;
		let pageInitCallerNavDiIDs = [, , , , , , , , , , ] as number[];

    }
}
