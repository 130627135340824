import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IUrunSatisGrupListe_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IUrunSatisGrupListe_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	UrunSatisGruplariSelect: any[];
	UrunSatisGruplariSelect_dummy: any[];
	NAVIGATE: any;
	NAVIGATE_dummy: any;
	UrunSatisGrubuSahaZiyaretteVarMi: any[];
	UrunSatisGrubuSahaZiyaretteVarMi_dummy: any[];
	UrunSatisGruplariDelete: number;
	UrunSatisGruplariDelete_dummy: number;
	Notify: boolean;
	Notify_dummy: boolean;

isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
}

export class UrunSatisGrupListe_ScreenBase extends React.PureComponent<IUrunSatisGrupListe_ScreenProps, any> {
	urunsatisgrupliste_246835_value_kuikaPaginationRef: React.RefObject<any>;
	urunsatisgrupliste_93316_value_kuikaTableRef: React.RefObject<any>;
    ml=[{"Id":"c27bb49b-e84b-2132-b611-3806f3391b29","Name":"tr_TR","ShortName":"tr","IsDefault":true,"LanguagePhrases":[]},{"Id":"06d2c229-146e-4155-8c55-a1684b084d7f","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"2e658edb-1651-4d6d-9ef4-06ed2a010d48","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":59539,"PropertyName":"label","Value":"Yeni Ürün Satış Grubu"},{"Id":472536,"PropertyName":"value","Value":"Ürün Satış Grup Listesi"},{"Id":93316,"PropertyName":"nodatafoundmessage","Value":"No data found."},{"Id":979531,"PropertyName":"title","Value":"Ürün Satış Grup Adı"},{"Id":209772,"PropertyName":"value","Value":"[datafield:urunsatisgrupisim]"},{"Id":242412,"PropertyName":"title","Value":"Kutudaki Adet"},{"Id":217667,"PropertyName":"value","Value":"[datafield:kutudakiadet]"},{"Id":286067,"PropertyName":"title","Value":"Düzenle"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);
		this.urunsatisgrupliste_246835_value_kuikaPaginationRef = React.createRef();
		this.urunsatisgrupliste_93316_value_kuikaTableRef = React.createRef();

        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        UrunSatisGruplariSelect: [],
	        NAVIGATE: "",
	        UrunSatisGrubuSahaZiyaretteVarMi: [],
	        UrunSatisGruplariDelete: 0,
	        Notify: false,

            isMasterPageInitLoaded: false,
  isMasterPageRecurringLoaded: false,
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("urunsatisgrupliste", "mainframe_screen");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (false){
            await this.UrunSatisGrupListePageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("urunsatisgrupliste", "mainframe_screen");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("urunsatisgrupliste", "mainframe_screen"    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.UrunSatisGrupListePageInit();
		}

        		if (prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded && this.state.isMasterPageInitLoaded === true) {await this.UrunSatisGrupListePageInit();}

	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

        setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  }

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  }

    UrunSatisGrupListePageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

        let localVar = {
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "UrunSatisGrupListe/UrunSatisGrupListePageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.UrunSatisGruplariSelect = result?.data.urunSatisGruplariSelect;
		
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.UrunSatisGrupListePageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	UrunSatisGrupListePageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			
			stateVars.dataSource_93316 = this.state.UrunSatisGruplariSelect;

	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




		UrunSatisGrupListeComponent_59539_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("UrunSatisGrupTanim", "UrunSatisGrupId", Guid.create().toString());

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "UrunSatisGrupListe", "UrunSatisGrupTanim", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "751860", null, "right", null, "350px", "100vh", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		UrunSatisGrupListeComponent_498925_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("UrunSatisGrupTanim", "UrunSatisGrupId", ReactSystemFunctions.value(this, "urunsatisgrupliste_93316_value", "id"));

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "UrunSatisGrupListe", "UrunSatisGrupTanim", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "318101", null, "right", null, "350px", "100vh", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


    UrunSatisGrupListeComponent_605384_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				UrunSatisGrupId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "urunsatisgrupliste_93316_value", "id"), "Guid")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "UrunSatisGrupListe/UrunSatisGrupListeComponent_605384_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.UrunSatisGrubuSahaZiyaretteVarMi = result?.data.urunSatisGrubuSahaZiyaretteVarMi;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.UrunSatisGrupListeComponent_605384_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
    UrunSatisGrupListeComponent_605384_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				Id_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "urunsatisgrupliste_93316_value", "id"), "Guid")
        }

if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, this.state.UrunSatisGrubuSahaZiyaretteVarMi?.length > 0 ? this.state.UrunSatisGrubuSahaZiyaretteVarMi[0]?.value : null), false)) {
            		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "UrunSatisGrupListe/UrunSatisGrupListeComponent_605384_onClick1_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.UrunSatisGruplariDelete = result?.data.urunSatisGruplariDelete;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.UrunSatisGrupListeComponent_605384_onClick2_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.UrunSatisGrupListeComponent_605384_onClick2_();
                  resolve();
                });
          })
        }


        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		UrunSatisGrupListeComponent_605384_onClick2_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, this.state.UrunSatisGrubuSahaZiyaretteVarMi?.length > 0 ? this.state.UrunSatisGrubuSahaZiyaretteVarMi[0]?.value : null), true)) {
            
				stateVars.Notify = await ReactSystemFunctions.notify(this, ReactSystemFunctions.translateCustomActions(this.ml,"4002ccf8_0c68_be99_63da_1a41840853e5_notify",this.defaultML,"Ürün grubu bir saha ziyaretinde kullanılmıştır, silinemez." ), "warning", "bottom-right", 5, null, null, null);

		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.UrunSatisGrupListeComponent_605384_onClick3_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.UrunSatisGrupListeComponent_605384_onClick3_();
                  resolve();
                });
          })
        }

            
            return isErrorOccurred;
        }
    UrunSatisGrupListeComponent_605384_onClick3_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
        }

if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, this.state.UrunSatisGrubuSahaZiyaretteVarMi?.length > 0 ? this.state.UrunSatisGrubuSahaZiyaretteVarMi[0]?.value : null), false)) {
            		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "UrunSatisGrupListe/UrunSatisGrupListeComponent_605384_onClick3_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.UrunSatisGruplariSelect = result?.data.urunSatisGruplariSelect;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.UrunSatisGrupListeComponent_605384_onClick4_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.UrunSatisGrupListeComponent_605384_onClick4_();
                  resolve();
                });
          })
        }


        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		UrunSatisGrupListeComponent_605384_onClick4_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, this.state.UrunSatisGrubuSahaZiyaretteVarMi?.length > 0 ? this.state.UrunSatisGrubuSahaZiyaretteVarMi[0]?.value : null), false)) {
            
				

		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.UrunSatisGrupListeComponent_605384_onClick5_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.UrunSatisGrupListeComponent_605384_onClick5_();
                  resolve();
                });
          })
        }

            
            return isErrorOccurred;
        }
		UrunSatisGrupListeComponent_605384_onClick5_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, this.state.UrunSatisGrubuSahaZiyaretteVarMi?.length > 0 ? this.state.UrunSatisGrubuSahaZiyaretteVarMi[0]?.value : null), false)) {
            
				stateVars.dataSource_93316 = this.state.UrunSatisGruplariSelect;

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

        } else {
            KuikaAppManager.hideSpinner(this);
        }

            
            return isErrorOccurred;
        }












    async callCallbackFunction(diId) {
        let isErrorOccurred = false;
		let pageInitCallerNavDiIDs = [751860, 318101] as number[];
		if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
			await this.UrunSatisGrupListePageInit();
		}

    }
}
