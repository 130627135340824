import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IKur_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IKur_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	GetTcmbDovizByKod: any;
	GetTcmbDovizByKod_dummy: any;
	Dovizsec: any[];
	Dovizsec_dummy: any[];

isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
}

export class Kur_ScreenBase extends React.PureComponent<IKur_ScreenProps, any> {
	kur_140160_value_kuikaDateRef: React.RefObject<any>;
    ml=[{"Id":"c27bb49b-e84b-2132-b611-3806f3391b29","Name":"tr_TR","ShortName":"tr","IsDefault":true,"LanguagePhrases":[]},{"Id":"06d2c229-146e-4155-8c55-a1684b084d7f","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"6bf5dbf4-e83e-45ca-b6f3-77d27c639377","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":140160,"PropertyName":"placeholder","Value":"Tarih seç..."},{"Id":244584,"PropertyName":"value","Value":"Avrupa Para Birimi"},{"Id":9570,"PropertyName":"value","Value":"Amerikan Doları"},{"Id":698255,"PropertyName":"value","Value":"Avustralya Doları"},{"Id":275425,"PropertyName":"value","Value":"Danimarka Kronu"},{"Id":784836,"PropertyName":"value","Value":"İngiliz Sterlini"},{"Id":761897,"PropertyName":"value","Value":"İsveç Kronu"},{"Id":332892,"PropertyName":"value","Value":"İsviçre Frangı"},{"Id":480589,"PropertyName":"value","Value":"Japon Yeni"},{"Id":611983,"PropertyName":"value","Value":"Kanada Doları"},{"Id":939371,"PropertyName":"value","Value":"Kuveyt Dinarı"},{"Id":654403,"PropertyName":"value","Value":"Norveç Kronu"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);
		this.kur_140160_value_kuikaDateRef = React.createRef();

        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        GetTcmbDovizByKod: "",
	        Dovizsec: [],

            isMasterPageInitLoaded: false,
  isMasterPageRecurringLoaded: false,
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("kur", "mainframe_screen");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (false){
            await this.kurPageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("kur", "mainframe_screen");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("kur", "mainframe_screen"    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.kurPageInit();
		}

        		if (prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded && this.state.isMasterPageInitLoaded === true) {await this.kurPageInit();}

	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

        setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  }

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  }

    kurPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

        let localVar = {
			secili_tarih_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kur_140160_value", "value", "", "", "")), "Date")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "kur/kurPageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.GetTcmbDovizByKod = result?.data.getTcmbDovizByKod;
		stateVars.Dovizsec = result?.data.dovizsec;
		formVars.kur_282044_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.eur : null);
		formVars.kur_658470_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.usd : null);
		formVars.kur_119060_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.aud : null);
		formVars.kur_8335_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.dkk : null);
		formVars.kur_820703_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.gbp : null);
		formVars.kur_64015_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.sek : null);
		formVars.kur_219198_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.chf : null);
		formVars.kur_381327_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.jpy : null);
		formVars.kur_590119_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.cad : null);
		formVars.kur_228443_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.kwd : null);
		formVars.kur_821618_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.nok : null);
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.kurPageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	kurPageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			formVars.kur_282044_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.eur : null);

			formVars.kur_658470_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.usd : null);

			formVars.kur_119060_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.aud : null);

			formVars.kur_8335_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.dkk : null);

			formVars.kur_820703_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.gbp : null);

			formVars.kur_64015_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.sek : null);

			formVars.kur_219198_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.chf : null);

			formVars.kur_381327_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.jpy : null);

			formVars.kur_590119_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.cad : null);

			formVars.kur_228443_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.kwd : null);

			formVars.kur_821618_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.nok : null);


	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




    kurComponent_140160_onChange = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				secili_tarih_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kur_140160_value", "value", "", "", "")), "Date")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "kur/kurComponent_140160_onChange", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.Dovizsec = result?.data.dovizsec;
			formVars.kur_282044_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.eur : null);
			formVars.kur_658470_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.usd : null);
			formVars.kur_119060_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.aud : null);
			formVars.kur_8335_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.dkk : null);
			formVars.kur_820703_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.gbp : null);
			formVars.kur_64015_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.sek : null);
			formVars.kur_219198_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.chf : null);
			formVars.kur_381327_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.jpy : null);
			formVars.kur_590119_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.cad : null);
			formVars.kur_228443_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.kwd : null);
			formVars.kur_821618_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.nok : null);
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.kurComponent_140160_onChange1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		kurComponent_140160_onChange1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				formVars.kur_282044_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.eur : null);

				formVars.kur_658470_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.usd : null);

				formVars.kur_119060_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.aud : null);

				formVars.kur_8335_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.dkk : null);

				formVars.kur_820703_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.gbp : null);

				formVars.kur_64015_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.sek : null);

				formVars.kur_219198_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.chf : null);

				formVars.kur_381327_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.jpy : null);

				formVars.kur_590119_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.cad : null);

				formVars.kur_228443_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.kwd : null);

				formVars.kur_821618_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.nok : null);


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    kurComponent_282044_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				secili_tarih_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kur_140160_value", "value", "", "", "")), "Date")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "kur/kurComponent_282044_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.Dovizsec = result?.data.dovizsec;
			formVars.kur_282044_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.eur : null);
			formVars.kur_658470_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.usd : null);
			formVars.kur_119060_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.aud : null);
			formVars.kur_8335_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.dkk : null);
			formVars.kur_820703_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.gbp : null);
			formVars.kur_64015_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.sek : null);
			formVars.kur_219198_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.chf : null);
			formVars.kur_381327_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.jpy : null);
			formVars.kur_590119_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.cad : null);
			formVars.kur_228443_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.kwd : null);
			formVars.kur_821618_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.nok : null);
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.kurComponent_282044_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		kurComponent_282044_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				formVars.kur_282044_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.eur : null);

				formVars.kur_658470_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.usd : null);

				formVars.kur_119060_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.aud : null);

				formVars.kur_8335_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.dkk : null);

				formVars.kur_820703_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.gbp : null);

				formVars.kur_64015_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.sek : null);

				formVars.kur_219198_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.chf : null);

				formVars.kur_381327_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.jpy : null);

				formVars.kur_590119_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.cad : null);

				formVars.kur_228443_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.kwd : null);

				formVars.kur_821618_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.nok : null);


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    kurComponent_658470_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				secili_tarih_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kur_140160_value", "value", "", "", "")), "Date")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "kur/kurComponent_658470_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.Dovizsec = result?.data.dovizsec;
			formVars.kur_282044_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.eur : null);
			formVars.kur_658470_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.usd : null);
			formVars.kur_119060_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.aud : null);
			formVars.kur_8335_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.dkk : null);
			formVars.kur_820703_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.gbp : null);
			formVars.kur_64015_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.sek : null);
			formVars.kur_219198_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.chf : null);
			formVars.kur_381327_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.jpy : null);
			formVars.kur_590119_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.cad : null);
			formVars.kur_228443_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.kwd : null);
			formVars.kur_821618_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.nok : null);
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.kurComponent_658470_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		kurComponent_658470_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				formVars.kur_282044_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.eur : null);

				formVars.kur_658470_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.usd : null);

				formVars.kur_119060_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.aud : null);

				formVars.kur_8335_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.dkk : null);

				formVars.kur_820703_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.gbp : null);

				formVars.kur_64015_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.sek : null);

				formVars.kur_219198_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.chf : null);

				formVars.kur_381327_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.jpy : null);

				formVars.kur_590119_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.cad : null);

				formVars.kur_228443_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.kwd : null);

				formVars.kur_821618_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.nok : null);


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    kurComponent_119060_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				secili_tarih_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kur_140160_value", "value", "", "", "")), "Date")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "kur/kurComponent_119060_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.Dovizsec = result?.data.dovizsec;
			formVars.kur_282044_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.eur : null);
			formVars.kur_658470_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.usd : null);
			formVars.kur_119060_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.aud : null);
			formVars.kur_8335_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.dkk : null);
			formVars.kur_820703_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.gbp : null);
			formVars.kur_64015_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.sek : null);
			formVars.kur_219198_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.chf : null);
			formVars.kur_381327_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.jpy : null);
			formVars.kur_590119_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.cad : null);
			formVars.kur_228443_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.kwd : null);
			formVars.kur_821618_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.nok : null);
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.kurComponent_119060_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		kurComponent_119060_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				formVars.kur_282044_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.eur : null);

				formVars.kur_658470_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.usd : null);

				formVars.kur_119060_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.aud : null);

				formVars.kur_8335_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.dkk : null);

				formVars.kur_820703_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.gbp : null);

				formVars.kur_64015_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.sek : null);

				formVars.kur_219198_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.chf : null);

				formVars.kur_381327_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.jpy : null);

				formVars.kur_590119_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.cad : null);

				formVars.kur_228443_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.kwd : null);

				formVars.kur_821618_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.nok : null);


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    kurComponent_8335_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				secili_tarih_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kur_140160_value", "value", "", "", "")), "Date")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "kur/kurComponent_8335_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.Dovizsec = result?.data.dovizsec;
			formVars.kur_282044_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.eur : null);
			formVars.kur_658470_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.usd : null);
			formVars.kur_119060_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.aud : null);
			formVars.kur_8335_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.dkk : null);
			formVars.kur_820703_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.gbp : null);
			formVars.kur_64015_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.sek : null);
			formVars.kur_219198_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.chf : null);
			formVars.kur_381327_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.jpy : null);
			formVars.kur_590119_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.cad : null);
			formVars.kur_228443_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.kwd : null);
			formVars.kur_821618_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.nok : null);
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.kurComponent_8335_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		kurComponent_8335_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				formVars.kur_282044_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.eur : null);

				formVars.kur_658470_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.usd : null);

				formVars.kur_119060_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.aud : null);

				formVars.kur_8335_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.dkk : null);

				formVars.kur_820703_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.gbp : null);

				formVars.kur_64015_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.sek : null);

				formVars.kur_219198_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.chf : null);

				formVars.kur_381327_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.jpy : null);

				formVars.kur_590119_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.cad : null);

				formVars.kur_228443_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.kwd : null);

				formVars.kur_821618_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.nok : null);


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    kurComponent_820703_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				secili_tarih_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kur_140160_value", "value", "", "", "")), "Date")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "kur/kurComponent_820703_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.Dovizsec = result?.data.dovizsec;
			formVars.kur_282044_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.eur : null);
			formVars.kur_658470_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.usd : null);
			formVars.kur_119060_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.aud : null);
			formVars.kur_8335_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.dkk : null);
			formVars.kur_820703_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.gbp : null);
			formVars.kur_64015_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.sek : null);
			formVars.kur_219198_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.chf : null);
			formVars.kur_381327_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.jpy : null);
			formVars.kur_590119_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.cad : null);
			formVars.kur_228443_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.kwd : null);
			formVars.kur_821618_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.nok : null);
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.kurComponent_820703_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		kurComponent_820703_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				formVars.kur_282044_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.eur : null);

				formVars.kur_658470_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.usd : null);

				formVars.kur_119060_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.aud : null);

				formVars.kur_8335_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.dkk : null);

				formVars.kur_820703_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.gbp : null);

				formVars.kur_64015_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.sek : null);

				formVars.kur_219198_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.chf : null);

				formVars.kur_381327_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.jpy : null);

				formVars.kur_590119_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.cad : null);

				formVars.kur_228443_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.kwd : null);

				formVars.kur_821618_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.nok : null);


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    kurComponent_64015_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				secili_tarih_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kur_140160_value", "value", "", "", "")), "Date")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "kur/kurComponent_64015_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.Dovizsec = result?.data.dovizsec;
			formVars.kur_282044_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.eur : null);
			formVars.kur_658470_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.usd : null);
			formVars.kur_119060_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.aud : null);
			formVars.kur_8335_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.dkk : null);
			formVars.kur_820703_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.gbp : null);
			formVars.kur_64015_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.sek : null);
			formVars.kur_219198_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.chf : null);
			formVars.kur_381327_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.jpy : null);
			formVars.kur_590119_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.cad : null);
			formVars.kur_228443_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.kwd : null);
			formVars.kur_821618_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.nok : null);
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.kurComponent_64015_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		kurComponent_64015_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				formVars.kur_282044_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.eur : null);

				formVars.kur_658470_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.usd : null);

				formVars.kur_119060_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.aud : null);

				formVars.kur_8335_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.dkk : null);

				formVars.kur_820703_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.gbp : null);

				formVars.kur_64015_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.sek : null);

				formVars.kur_219198_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.chf : null);

				formVars.kur_381327_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.jpy : null);

				formVars.kur_590119_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.cad : null);

				formVars.kur_228443_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.kwd : null);

				formVars.kur_821618_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.nok : null);


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    kurComponent_219198_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				secili_tarih_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kur_140160_value", "value", "", "", "")), "Date")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "kur/kurComponent_219198_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.Dovizsec = result?.data.dovizsec;
			formVars.kur_282044_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.eur : null);
			formVars.kur_658470_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.usd : null);
			formVars.kur_119060_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.aud : null);
			formVars.kur_8335_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.dkk : null);
			formVars.kur_820703_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.gbp : null);
			formVars.kur_64015_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.sek : null);
			formVars.kur_219198_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.chf : null);
			formVars.kur_381327_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.jpy : null);
			formVars.kur_590119_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.cad : null);
			formVars.kur_228443_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.kwd : null);
			formVars.kur_821618_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.nok : null);
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.kurComponent_219198_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		kurComponent_219198_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				formVars.kur_282044_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.eur : null);

				formVars.kur_658470_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.usd : null);

				formVars.kur_119060_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.aud : null);

				formVars.kur_8335_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.dkk : null);

				formVars.kur_820703_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.gbp : null);

				formVars.kur_64015_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.sek : null);

				formVars.kur_219198_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.chf : null);

				formVars.kur_381327_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.jpy : null);

				formVars.kur_590119_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.cad : null);

				formVars.kur_228443_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.kwd : null);

				formVars.kur_821618_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.nok : null);


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    kurComponent_381327_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				secili_tarih_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kur_140160_value", "value", "", "", "")), "Date")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "kur/kurComponent_381327_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.Dovizsec = result?.data.dovizsec;
			formVars.kur_282044_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.eur : null);
			formVars.kur_658470_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.usd : null);
			formVars.kur_119060_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.aud : null);
			formVars.kur_8335_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.dkk : null);
			formVars.kur_820703_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.gbp : null);
			formVars.kur_64015_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.sek : null);
			formVars.kur_219198_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.chf : null);
			formVars.kur_381327_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.jpy : null);
			formVars.kur_590119_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.cad : null);
			formVars.kur_228443_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.kwd : null);
			formVars.kur_821618_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.nok : null);
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.kurComponent_381327_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		kurComponent_381327_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				formVars.kur_282044_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.eur : null);

				formVars.kur_658470_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.usd : null);

				formVars.kur_119060_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.aud : null);

				formVars.kur_8335_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.dkk : null);

				formVars.kur_820703_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.gbp : null);

				formVars.kur_64015_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.sek : null);

				formVars.kur_219198_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.chf : null);

				formVars.kur_381327_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.jpy : null);

				formVars.kur_590119_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.cad : null);

				formVars.kur_228443_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.kwd : null);

				formVars.kur_821618_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.nok : null);


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    kurComponent_590119_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				secili_tarih_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kur_140160_value", "value", "", "", "")), "Date")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "kur/kurComponent_590119_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.Dovizsec = result?.data.dovizsec;
			formVars.kur_282044_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.eur : null);
			formVars.kur_658470_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.usd : null);
			formVars.kur_119060_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.aud : null);
			formVars.kur_8335_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.dkk : null);
			formVars.kur_820703_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.gbp : null);
			formVars.kur_64015_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.sek : null);
			formVars.kur_219198_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.chf : null);
			formVars.kur_381327_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.jpy : null);
			formVars.kur_590119_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.cad : null);
			formVars.kur_228443_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.kwd : null);
			formVars.kur_821618_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.nok : null);
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.kurComponent_590119_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		kurComponent_590119_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				formVars.kur_282044_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.eur : null);

				formVars.kur_658470_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.usd : null);

				formVars.kur_119060_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.aud : null);

				formVars.kur_8335_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.dkk : null);

				formVars.kur_820703_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.gbp : null);

				formVars.kur_64015_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.sek : null);

				formVars.kur_219198_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.chf : null);

				formVars.kur_381327_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.jpy : null);

				formVars.kur_590119_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.cad : null);

				formVars.kur_228443_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.kwd : null);

				formVars.kur_821618_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.nok : null);


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    kurComponent_228443_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				secili_tarih_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kur_140160_value", "value", "", "", "")), "Date")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "kur/kurComponent_228443_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.Dovizsec = result?.data.dovizsec;
			formVars.kur_282044_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.eur : null);
			formVars.kur_658470_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.usd : null);
			formVars.kur_119060_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.aud : null);
			formVars.kur_8335_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.dkk : null);
			formVars.kur_820703_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.gbp : null);
			formVars.kur_64015_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.sek : null);
			formVars.kur_219198_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.chf : null);
			formVars.kur_381327_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.jpy : null);
			formVars.kur_590119_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.cad : null);
			formVars.kur_228443_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.kwd : null);
			formVars.kur_821618_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.nok : null);
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.kurComponent_228443_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		kurComponent_228443_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				formVars.kur_282044_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.eur : null);

				formVars.kur_658470_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.usd : null);

				formVars.kur_119060_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.aud : null);

				formVars.kur_8335_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.dkk : null);

				formVars.kur_820703_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.gbp : null);

				formVars.kur_64015_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.sek : null);

				formVars.kur_219198_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.chf : null);

				formVars.kur_381327_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.jpy : null);

				formVars.kur_590119_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.cad : null);

				formVars.kur_228443_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.kwd : null);

				formVars.kur_821618_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.nok : null);


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    kurComponent_821618_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				secili_tarih_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kur_140160_value", "value", "", "", "")), "Date")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "kur/kurComponent_821618_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.Dovizsec = result?.data.dovizsec;
			formVars.kur_282044_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.eur : null);
			formVars.kur_658470_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.usd : null);
			formVars.kur_119060_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.aud : null);
			formVars.kur_8335_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.dkk : null);
			formVars.kur_820703_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.gbp : null);
			formVars.kur_64015_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.sek : null);
			formVars.kur_219198_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.chf : null);
			formVars.kur_381327_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.jpy : null);
			formVars.kur_590119_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.cad : null);
			formVars.kur_228443_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.kwd : null);
			formVars.kur_821618_value = ReactSystemFunctions.toString(this, stateVars.Dovizsec?.length > 0 ? stateVars.Dovizsec[0]?.nok : null);
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.kurComponent_821618_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		kurComponent_821618_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				formVars.kur_282044_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.eur : null);

				formVars.kur_658470_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.usd : null);

				formVars.kur_119060_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.aud : null);

				formVars.kur_8335_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.dkk : null);

				formVars.kur_820703_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.gbp : null);

				formVars.kur_64015_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.sek : null);

				formVars.kur_219198_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.chf : null);

				formVars.kur_381327_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.jpy : null);

				formVars.kur_590119_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.cad : null);

				formVars.kur_228443_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.kwd : null);

				formVars.kur_821618_value = ReactSystemFunctions.toString(this, this.state.Dovizsec?.length > 0 ? this.state.Dovizsec[0]?.nok : null);


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }






    async callCallbackFunction(diId) {
        let isErrorOccurred = false;

    }
}
