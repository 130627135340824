import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface ITableDeneme_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface ITableDeneme_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	bolgeHaftaTablo: any[];
	bolgeHaftaTablo_dummy: any[];
	isComp505878Visible: 'visible' | 'hidden';
	isComp455666Visible: 'visible' | 'hidden';
	isComp100134Visible: 'visible' | 'hidden';
	isComp343092Enabled: 'enabled' | 'disabled';
	isComp505878Enabled: 'enabled' | 'disabled';
	isComp455666Enabled: 'enabled' | 'disabled';
	isComp100134Enabled: 'enabled' | 'disabled';
	isComp549261Enabled: 'enabled' | 'disabled';
	isComp541293Enabled: 'enabled' | 'disabled';
	isComp392184Enabled: 'enabled' | 'disabled';
	isComp197037Enabled: 'enabled' | 'disabled';
	isComp553293Enabled: 'enabled' | 'disabled';

isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
}

export class TableDeneme_ScreenBase extends React.PureComponent<ITableDeneme_ScreenProps, any> {
	tabledeneme_236541_value_kuikaTableRef: React.RefObject<any>;
    ml=[{"Id":"c27bb49b-e84b-2132-b611-3806f3391b29","Name":"tr_TR","ShortName":"tr","IsDefault":true,"LanguagePhrases":[]},{"Id":"06d2c229-146e-4155-8c55-a1684b084d7f","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"66ff05cc-f149-440d-aa3a-0394d1410baa","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":236541,"PropertyName":"nodatafoundmessage","Value":"No data found."},{"Id":50903,"PropertyName":"title","Value":"Bölge"},{"Id":15761,"PropertyName":"value","Value":"[datafield:bolge]"},{"Id":343092,"PropertyName":"placeholder","Value":"Type here..."},{"Id":285271,"PropertyName":"title","Value":"Son Gün Satış"},{"Id":383994,"PropertyName":"value","Value":"[datafield:toplamtutar1]"},{"Id":505878,"PropertyName":"placeholder","Value":"Type here..."},{"Id":436974,"PropertyName":"value","Value":"[datafield:toplamtutar2]"},{"Id":455666,"PropertyName":"placeholder","Value":"Type here..."},{"Id":12919,"PropertyName":"value","Value":"[datafield:toplamtutar3]"},{"Id":100134,"PropertyName":"placeholder","Value":"Type here..."},{"Id":561853,"PropertyName":"value","Value":"[datafield:toplamtutar4]"},{"Id":549261,"PropertyName":"placeholder","Value":"Type here..."},{"Id":565821,"PropertyName":"value","Value":"[datafield:toplamtutar5]"},{"Id":541293,"PropertyName":"placeholder","Value":"Type here..."},{"Id":911577,"PropertyName":"value","Value":"[datafield:toplamtutar6]"},{"Id":392184,"PropertyName":"placeholder","Value":"Type here..."},{"Id":625643,"PropertyName":"value","Value":"[datafield:toplamtutar7]"},{"Id":197037,"PropertyName":"placeholder","Value":"Type here..."},{"Id":878278,"PropertyName":"value","Value":"[datafield:toplamtutar8]"},{"Id":553293,"PropertyName":"placeholder","Value":"Type here..."}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);
		this.tabledeneme_236541_value_kuikaTableRef = React.createRef();

        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        bolgeHaftaTablo: [],
	        isComp505878Visible: 'hidden',
	        isComp455666Visible: 'hidden',
	        isComp100134Visible: 'hidden',
	        isComp343092Enabled: 'disabled',
	        isComp505878Enabled: 'disabled',
	        isComp455666Enabled: 'disabled',
	        isComp100134Enabled: 'disabled',
	        isComp549261Enabled: 'disabled',
	        isComp541293Enabled: 'disabled',
	        isComp392184Enabled: 'disabled',
	        isComp197037Enabled: 'disabled',
	        isComp553293Enabled: 'disabled',

            isMasterPageInitLoaded: false,
  isMasterPageRecurringLoaded: false,
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("tabledeneme", "mainframe_screen");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (false){
            await this.tableDenemePageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("tabledeneme", "mainframe_screen");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("tabledeneme", "mainframe_screen"    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.tableDenemePageInit();
		}

        		if (prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded && this.state.isMasterPageInitLoaded === true) {await this.tableDenemePageInit();}

	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

        setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  }

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  }

    tableDenemePageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

        let localVar = {
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "tableDeneme/tableDenemePageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.bolgeHaftaTablo = result?.data.bolgeHaftaTablo;
		
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.tableDenemePageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	tableDenemePageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			
			stateVars.dataSource_236541 = this.state.bolgeHaftaTablo;
			formVars.tabledeneme_15761_value = ReactSystemFunctions.toString(this, this.state.bolgeHaftaTablo?.length > 0 ? this.state.bolgeHaftaTablo[0]?.bolge : null);

			formVars.Table_236541_bolge_value = ReactSystemFunctions.toString(this, this.state.bolgeHaftaTablo?.length > 0 ? this.state.bolgeHaftaTablo[0]?.bolge : null);

			formVars.tabledeneme_383994_value = ReactSystemFunctions.toString(this, this.state.bolgeHaftaTablo?.length > 0 ? this.state.bolgeHaftaTablo[0]?.toplamTutar1 : null);

			formVars.Table_236541_toplamTutar1_value = ReactSystemFunctions.toString(this, this.state.bolgeHaftaTablo?.length > 0 ? this.state.bolgeHaftaTablo[0]?.toplamTutar1 : null);

			formVars.tabledeneme_436974_value = ReactSystemFunctions.toString(this, this.state.bolgeHaftaTablo?.length > 0 ? this.state.bolgeHaftaTablo[0]?.toplamTutar2 : null);

			formVars.Table_236541_toplamTutar2_value = ReactSystemFunctions.toString(this, this.state.bolgeHaftaTablo?.length > 0 ? this.state.bolgeHaftaTablo[0]?.toplamTutar2 : null);

			formVars.tabledeneme_12919_value = ReactSystemFunctions.toString(this, this.state.bolgeHaftaTablo?.length > 0 ? this.state.bolgeHaftaTablo[0]?.toplamTutar3 : null);

			formVars.Table_236541_toplamTutar3_value = ReactSystemFunctions.toString(this, this.state.bolgeHaftaTablo?.length > 0 ? this.state.bolgeHaftaTablo[0]?.toplamTutar3 : null);

			formVars.tabledeneme_561853_value = ReactSystemFunctions.toString(this, this.state.bolgeHaftaTablo?.length > 0 ? this.state.bolgeHaftaTablo[0]?.toplamTutar4 : null);

			formVars.Table_236541_toplamTutar4_value = ReactSystemFunctions.toString(this, this.state.bolgeHaftaTablo?.length > 0 ? this.state.bolgeHaftaTablo[0]?.toplamTutar4 : null);

			formVars.tabledeneme_565821_value = ReactSystemFunctions.toString(this, this.state.bolgeHaftaTablo?.length > 0 ? this.state.bolgeHaftaTablo[0]?.toplamTutar5 : null);

			formVars.Table_236541_toplamTutar5_value = ReactSystemFunctions.toString(this, this.state.bolgeHaftaTablo?.length > 0 ? this.state.bolgeHaftaTablo[0]?.toplamTutar5 : null);

			formVars.tabledeneme_911577_value = ReactSystemFunctions.toString(this, this.state.bolgeHaftaTablo?.length > 0 ? this.state.bolgeHaftaTablo[0]?.toplamTutar6 : null);

			formVars.Table_236541_toplamTutar6_value = ReactSystemFunctions.toString(this, this.state.bolgeHaftaTablo?.length > 0 ? this.state.bolgeHaftaTablo[0]?.toplamTutar6 : null);

			formVars.tabledeneme_625643_value = ReactSystemFunctions.toString(this, this.state.bolgeHaftaTablo?.length > 0 ? this.state.bolgeHaftaTablo[0]?.toplamTutar7 : null);

			formVars.Table_236541_toplamTutar7_value = ReactSystemFunctions.toString(this, this.state.bolgeHaftaTablo?.length > 0 ? this.state.bolgeHaftaTablo[0]?.toplamTutar7 : null);

			formVars.tabledeneme_878278_value = ReactSystemFunctions.toString(this, this.state.bolgeHaftaTablo?.length > 0 ? this.state.bolgeHaftaTablo[0]?.toplamTutar8 : null);

			formVars.Table_236541_toplamTutar8_value = ReactSystemFunctions.toString(this, this.state.bolgeHaftaTablo?.length > 0 ? this.state.bolgeHaftaTablo[0]?.toplamTutar8 : null);


	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }






    async callCallbackFunction(diId) {
        let isErrorOccurred = false;

    }
}
