import * as React from "react";
import { Rut_ScreenBase } from "./rut-base";
import { Spacer, KPassword, KSteps, KStep, KLabel, KNumericbox, KButton, KToggle, KCheckbox, KMonthPicker, KDatatimePicker, KRating, KSelectBox, KMultiSelect, KTextarea, KTextbox, KFileDownload, KFilePicker, KLanguageSelect, KLocationSelect, KLocationDisplay, KLocationPicker, KSignature, KIcon, KMDEditor, KMDViewer, KRadio, KRadioGroup, KCalendar, KIFrame, KAvatarMenu, KAvatarMenuItem, KSpace, KSlider, KRadioButton, KThumbnail } from "../../kuika";
import { MfeContainer, TextArea, WebView, Icon, Label, Signature, HorizontalStack, VerticalStack, LanguageSelector, MDViewer, QRRenderer, BarcodeRenderer, Divider, MDEditor, Button, Rating, Percent, Currency, NumberInput, BarcodeInput, Password, TextInput, Image, Email, AreaChart, BarChart, DonutChart, GaugeChart, LineChart, PieChart, Header, Footer, Switch, Url, CountDown, ProgressCircle, ProgressBar, CollapsePanel, Collapse, Slider, KAreaChart, KBarChart, KDonutChart, KGaugeChart, KLineChart, KPieChart, KFooter, KHeader, KRow, KCol, KTab, KTabItem, KTable, KTableHeader, KTableRow, KTableColumn, KGallery, KPagination, KDrawer, KModal, KVideo, KCarousel, KFlexGrid, KPanel, KDropDown, KDropDownItem, KContentMenu, KContentMenuItem, GoogleMaps, GoogleMapsMarker, GoogleMapsMarkerPopup, KContentMenuPanel, KBadge, Badge, RangeSlider, Checkbox, Chat, RangeDate, RangeDateTime, Stopwatch, PlacesAutocompleteInput, CronMaker, SingleSelect, MultiSelect, AutoComplete, StripeCard, PaymentElementStripe, CountUp, Tree, KDatePicker, YearPicker, Tooltip, Calendar, LayoutHeader, LayoutFooter, LayoutLeft, LayoutRight, LocationPicker, Timeline, Stepper, StepperItem, Menu, MenuItem, SubMenu, RadialChart, MixedChart, MixedChartLineItem, MixedChartPointItem, ChartLabel, KanbanBoard, ProcessAutomation, RadioGroup, BigFileUpload, PivotTable } from "../../components/web-components";
import { Drawer, Form, Spin, Typography } from "antd";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withForm } from "../../shared/hoc/with-form";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { withContext } from "../../shared/hoc/with-context";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { showPhotoTake, hidePhotoTake, showStripeDrawer, hideStripeDrawer,showIyzicoDrawer, hideIyzicoDrawer } from "../../redux/photo-take/actions";
import { AppState} from "../../redux/root-reducer";
import { connect } from "react-redux";
import moment from "moment";
import {MainFrame_Screen} from "../mainframe/mainframe";



declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [

];

class Rut_Screen extends Rut_ScreenBase
{
    render() {
        if (this.state.isPageVisible === false) {return (<></>);}
        return (
            <>
              <MainFrame_Screen form={this.props.form} initialValues={{rut_629287_value: { startValue: (""), endValue: ("") },
							rut_447805_value: this.state.GunlukRotaSecim2?.at?.(0)?.tmR_String ?? undefined
}} screenInputs={this.props.screenInputs} setMasterPageInitLoaded={this.setMasterPageInitLoaded} setMasterPageRecurringLoaded={this.setMasterPageRecurringLoaded} isMasterPageInitLoaded={this.state.isMasterPageInitLoaded} isMasterPageRecurringLoaded={this.state.isMasterPageRecurringLoaded} >
                <Spin spinning={this.state.spinnerCount > 0}>
                    
                        
<div id="rut_body" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"backgroundColor":"rgba(244, 244, 244, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","display":"block","overflow":"auto","minHeight":"calc(100vh - 70px - 60px - 0px - 0px)","maxHeight":"calc(100vh - 70px - 60px - 0px - 0px)","height":"calc(100vh - 70px - 60px - 0px - 0px)"} as any}>
<KRow id="853574" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="813379" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></KCol>

<KCol id="952903" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-right","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></KCol>
</KRow>

<KRow id="713186" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"boxShadow":"1px 2px 5px 1px rgba(0, 0, 0, 0.5)","backgroundColor":"rgba(255, 255, 255, 1)","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="126288" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KRow id="395068" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="218718" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="625462" value={ReactSystemFunctions.translate(this.ml, 625462, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":600,"fontSize":"28pt","color":"rgba(0, 0, 0, 1)"} as any}></Label>
</KCol>
</KRow>

<KRow id="377517" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="880926" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Form.Item className='kFormItem' name='rut_629287_value' >
<RangeDate id="629287" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.RutComponent_629287_onChange()} } format="DD/MM/YYYY" startDatePlaceholder={ReactSystemFunctions.translate(this.ml, 629287, "startDatePlaceholder",this.defaultML)} endDatePlaceholder={ReactSystemFunctions.translate(this.ml, 629287, "endDatePlaceholder",this.defaultML)} allowClear={false} style={{"borderTopLeftRadius":4,"borderTopRightRadius":4,"borderBottomRightRadius":4,"borderBottomLeftRadius":4,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></RangeDate>
</Form.Item>
</KCol>

<KCol id="227450" xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></KCol>

<KCol id="724761" xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Form.Item className='kFormItem' name='rut_447805_value' >
<KSelectBox id="447805" onSelect={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.RutComponent_447805_onSelect()} } kuikaRef={this.rut_447805_value_kuikaSelectBoxRef} options={this.state.GunlukRotaSecim2} placeholder={ReactSystemFunctions.translate(this.ml, 447805, "placeholder",this.defaultML)} allowClear={true} autoClearSearchValue={true} showSearch={true} widthType="fill" containsNullItem={false} sortBy="A-Z" options={this.state.dataSource_447805} datavaluefield='id' datatextfield='tmR_String' style={{"borderTopLeftRadius":4,"borderTopRightRadius":4,"borderBottomRightRadius":4,"borderBottomLeftRadius":4,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></KSelectBox>
</Form.Item>
</KCol>
</KRow>

<KRow id="458824" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="716378" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"backgroundColor":"rgba(255, 255, 255, 1)","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-center","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KTable id="994722" kuikaRef={this.rut_994722_value_kuikaTableRef} form={this.props.form} dataSource={this.state.GunlukRotaSecim2} size="middle" bordered={true} loading={false} transformedOnMobileResolution={false} showHeader={true} fixedHeader={false} editable={false} tableWidthMode="fit" globalSearch={false} searchable={false} sorter={false} filtering={true} pagination={true} pageSize={10} showSizeChanger={false} columnChooser={false} resizableColumns={false} striped={false} stripedColor="#F5F7FA" insertRowActive={false} insertRowPosition="top" showNoDataFound={true} nodatafoundmessage={ReactSystemFunctions.translate(this.ml, 994722, "nodatafoundmessage",this.defaultML)} multiSelect={false} export={false} editableAlwaysActive={false} options={this.state.dataSource_994722} style={{"borderTopLeftRadius":0,"borderTopRightRadius":0,"borderBottomRightRadius":0,"borderBottomLeftRadius":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KTableHeader id="686187" hideOnMobileResolution={false} textDirection="Default" style={{"borderTopLeftRadius":0,"borderTopRightRadius":0,"borderBottomRightRadius":0,"borderBottomLeftRadius":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-center","fontFamily":"Poppins","fontWeight":400,"fontSize":"14px","color":"rgba(0, 0, 0, 1)","lineHeight":"20px"} as any}></KTableHeader>

<KTableRow id="814688" hoverFontColor="red" hoverBgColor="#F5F5F5" style={{"borderColor":"rgba(0, 0, 0, 1)","borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"backgroundColor":"rgba(217, 217, 217, 0.2)","paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KTableColumn id="627710" title={ReactSystemFunctions.translate(this.ml, 627710, "title",this.defaultML)} isEditableColumn={true} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="304231" value="[datafield:formula]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"18px","color":"rgba(68, 68, 68, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='Table_994722_formula' >
<TextInput id="495956" onChange={(e: any) => {this.props.form.setFieldValue('Table_994722_formula', e?.target?.value ?? e );}} defaultValue="[datafield:formula]" placeholder={ReactSystemFunctions.translate(this.ml, 495956, "placeholder",this.defaultML)} allowClear={false} bordered={true} disabled={false} type="text" iconColor="rgba(0, 0, 0, 1)" formatter="None" isCharOnly={false} autoComplete={true} style={{"borderTopLeftRadius":4,"borderTopRightRadius":4,"borderBottomRightRadius":4,"borderBottomLeftRadius":4,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></TextInput>
</Form.Item>
</KTableColumn>

<KTableColumn id="396924" title={ReactSystemFunctions.translate(this.ml, 396924, "title",this.defaultML)} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="559298" value="[datafield:formula1]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"18px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</KTableColumn>

<KTableColumn id="962630" title={ReactSystemFunctions.translate(this.ml, 962630, "title",this.defaultML)} isEditableColumn={true} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"height":"60px","alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="749895" value="[datafield:date1]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"18px","color":"rgba(68, 68, 68, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='Table_994722_date1' >
<KDatePicker id="316365" kuikaRef={this.rut_316365_value_kuikaDatetimeRef} onChange={(e: any) => {this.props.form.setFieldValue('Table_994722_date1', e?.target?.value ?? e );}} defaultValue="[datafield:date1]" placeholder={ReactSystemFunctions.translate(this.ml, 316365, "placeholder",this.defaultML)} allowClear={true} format="DD-MM-YYYY HH:mm:ss" use12Hours={false} style={{"borderTopLeftRadius":4,"borderTopRightRadius":4,"borderBottomRightRadius":4,"borderBottomLeftRadius":4,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></KDatePicker>
</Form.Item>
</KTableColumn>

<KTableColumn id="559947" title={ReactSystemFunctions.translate(this.ml, 559947, "title",this.defaultML)} isEditableColumn={true} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"height":"60px","alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="128442" value="[datafield:store]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"18px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</KTableColumn>

<KTableColumn id="895769" title={ReactSystemFunctions.translate(this.ml, 895769, "title",this.defaultML)} isEditableColumn={true} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"height":"60px","alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="27389" value="[datafield:type1]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"18px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</KTableColumn>

<KTableColumn id="929519" title={ReactSystemFunctions.translate(this.ml, 929519, "title",this.defaultML)} isEditableColumn={true} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"height":"60px","alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="425405" value="[datafield:account]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"18px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</KTableColumn>

<KTableColumn id="294863" title={ReactSystemFunctions.translate(this.ml, 294863, "title",this.defaultML)} isEditableColumn={true} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"height":"60px","alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="524238" value="[datafield:status]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"18px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</KTableColumn>

<KTableColumn id="181663" title={ReactSystemFunctions.translate(this.ml, 181663, "title",this.defaultML)} isEditableColumn={true} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"height":"60px","alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="419560" value="[datafield:startWeek]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"18px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</KTableColumn>

<KTableColumn id="664121" title={ReactSystemFunctions.translate(this.ml, 664121, "title",this.defaultML)} isEditableColumn={true} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"height":"60px","alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="199811" value="[datafield:code]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"18px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</KTableColumn>

<KTableColumn id="685256" title={ReactSystemFunctions.translate(this.ml, 685256, "title",this.defaultML)} isEditableColumn={true} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"height":"60px","alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="685738" value="[datafield:division]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"18px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</KTableColumn>

<KTableColumn id="155482" title={ReactSystemFunctions.translate(this.ml, 155482, "title",this.defaultML)} isEditableColumn={true} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"height":"60px","alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="189089" value="[datafield:city]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"18px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</KTableColumn>

<KTableColumn id="121183" title={ReactSystemFunctions.translate(this.ml, 121183, "title",this.defaultML)} isEditableColumn={true} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"height":"60px","alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="507973" value="[datafield:district]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"18px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</KTableColumn>

<KTableColumn id="583643" title={ReactSystemFunctions.translate(this.ml, 583643, "title",this.defaultML)} isEditableColumn={true} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"height":"60px","alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="313097" value="[datafield:tmr]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"18px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</KTableColumn>

<KTableColumn id="864212" title={ReactSystemFunctions.translate(this.ml, 864212, "title",this.defaultML)} isEditableColumn={true} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"height":"60px","alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="365103" value="[datafield:unitQty]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"18px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</KTableColumn>

<KTableColumn id="999835" title={ReactSystemFunctions.translate(this.ml, 999835, "title",this.defaultML)} isEditableColumn={true} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"height":"60px","alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="412377" value="[datafield:unitType]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"18px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</KTableColumn>

<KTableColumn id="485019" title={ReactSystemFunctions.translate(this.ml, 485019, "title",this.defaultML)} isEditableColumn={true} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"height":"60px","alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="603034" value="[datafield:freq]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"18px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</KTableColumn>

<KTableColumn id="40440" title={ReactSystemFunctions.translate(this.ml, 40440, "title",this.defaultML)} isEditableColumn={true} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"height":"60px","alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="824653" value="[datafield:week]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"18px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</KTableColumn>

<KTableColumn id="171870" title={ReactSystemFunctions.translate(this.ml, 171870, "title",this.defaultML)} isEditableColumn={true} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"height":"60px","alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="742515" value="[datafield:day1]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"18px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</KTableColumn>

<KTableColumn id="803954" title={ReactSystemFunctions.translate(this.ml, 803954, "title",this.defaultML)} isEditableColumn={true} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"height":"60px","alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="384525" value="[datafield:row1]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"18px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</KTableColumn>

<KTableColumn id="83166" title={ReactSystemFunctions.translate(this.ml, 83166, "title",this.defaultML)} isEditableColumn={true} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"height":"60px","alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="199414" value="[datafield:realizationWeek]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"18px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</KTableColumn>

<KTableColumn id="393763" title={ReactSystemFunctions.translate(this.ml, 393763, "title",this.defaultML)} isEditableColumn={true} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"height":"60px","alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="662602" value="[datafield:realizationDay]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"18px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</KTableColumn>

<KTableColumn id="243997" title={ReactSystemFunctions.translate(this.ml, 243997, "title",this.defaultML)} isEditableColumn={true} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"height":"60px","alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="697368" value="[datafield:comments]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"18px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</KTableColumn>

<KTableColumn id="882560" title={ReactSystemFunctions.translate(this.ml, 882560, "title",this.defaultML)} isEditableColumn={true} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"height":"60px","alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="529587" value="[datafield:islem]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"18px","color":"rgba(68, 68, 68, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='Table_994722_islem' >
<TextInput id="376261" onChange={(e: any) => {this.props.form.setFieldValue('Table_994722_islem', e?.target?.value ?? e );}} defaultValue="[datafield:islem]" placeholder={ReactSystemFunctions.translate(this.ml, 376261, "placeholder",this.defaultML)} allowClear={false} bordered={true} disabled={false} type="text" iconColor="rgba(0, 0, 0, 1)" formatter="None" isCharOnly={false} autoComplete={true} style={{"borderTopLeftRadius":4,"borderTopRightRadius":4,"borderBottomRightRadius":4,"borderBottomLeftRadius":4,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></TextInput>
</Form.Item>
</KTableColumn>

<KTableColumn id="216200" title={ReactSystemFunctions.translate(this.ml, 216200, "title",this.defaultML)} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<HorizontalStack id="555753" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Button id="132342" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.RutComponent_132342_onClick()} } showCursorPointer label={ReactSystemFunctions.translate(this.ml, 132342, "label",this.defaultML)} size="middle" loading={false} ghost={false} block={false} htmlType="button" icon="create" iconPosition="left" danger={false} style={{"borderTopLeftRadius":4,"borderTopRightRadius":4,"borderBottomRightRadius":4,"borderBottomLeftRadius":4,"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"backgroundColor":"rgba(3, 88, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":16,"paddingBottom":8,"paddingLeft":16,"width":"90px","alignItems":"center","textAlign":"-webkit-center","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Button>
</HorizontalStack>
</KTableColumn>
</KTableRow>
</KTable>
</KCol>
</KRow>
</KCol>
</KRow>
</div>

                    
                </Spin>
              </MainFrame_Screen>
            </>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        spinnerCount: state.spinnerReducer.spinnerCount,
        photoTakeVisible: state.photoTakeReducer.visible
    };
  }
  const mapDispatchToProps = dispatch => ({
    showPhotoTake: () => dispatch(showPhotoTake()),
    hidePhotoTake: () => dispatch(hidePhotoTake()),
    showStripeDrawer: (data,callback,that,future) => dispatch(showStripeDrawer(data,callback,that,future)),
    hideStripeDrawer: (data,callback,that) => dispatch(hideStripeDrawer(data,callback,that)),
    showIyzicoDrawer: (data,callback,that) => dispatch(showIyzicoDrawer(data,callback,that)),
    hideIyzicoDrawer: (data,callback,that) => dispatch(hideIyzicoDrawer(data,callback,that))
  });
const tmp = withGoogleLogin(withContext(withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(Rut_Screen))))));
export { tmp as Rut_Screen };
//export default tmp;
//export { tmp as Rut_Screen };

