import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface ISahaZiyaretListesi_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface ISahaZiyaretListesi_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	SahaZiyaretFormuTableSelectAll: any[];
	SahaZiyaretFormuTableSelectAll_dummy: any[];
	SahaZiyaretFormuTableCount: any[];
	SahaZiyaretFormuTableCount_dummy: any[];
	NAVIGATE: any;
	NAVIGATE_dummy: any;
	ExportExcel: any;
	ExportExcel_dummy: any;

isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
}

export class SahaZiyaretListesi_ScreenBase extends React.PureComponent<ISahaZiyaretListesi_ScreenProps, any> {
	sahaziyaretlistesi_142089_value_kuikaDatePickerRef: React.RefObject<any>;
	sahaziyaretlistesi_93316_value_kuikaTableRef: React.RefObject<any>;
	sahaziyaretlistesi_609608_value_kuikaPaginationRef: React.RefObject<any>;
    ml=[{"Id":"c27bb49b-e84b-2132-b611-3806f3391b29","Name":"tr_TR","ShortName":"tr","IsDefault":true,"LanguagePhrases":[]},{"Id":"06d2c229-146e-4155-8c55-a1684b084d7f","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"7dfe4347-0a9f-43b2-a28c-814ab88664ff","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":142089,"PropertyName":"startDatePlaceholder","Value":"Başlangıç ​​Tarihi"},{"Id":142089,"PropertyName":"endDatePlaceholder","Value":"Bitiş Tarihi"},{"Id":93316,"PropertyName":"nodatafoundmessage","Value":"Veri bulunamadı"},{"Id":111988,"PropertyName":"title","Value":"Saha Ziyaret Listesi"},{"Id":307591,"PropertyName":"value","Value":"Ziyaret No :"},{"Id":467670,"PropertyName":"value","Value":"[datafield:ziyaretno]"},{"Id":383391,"PropertyName":"value","Value":"Ziyaretçi :"},{"Id":885840,"PropertyName":"value","Value":"[datafield:formsahibi]"},{"Id":556564,"PropertyName":"value","Value":"Mağaza :"},{"Id":361895,"PropertyName":"value","Value":"[datafield:magazaadi]"},{"Id":533367,"PropertyName":"value","Value":"İlişki Puanı :"},{"Id":9332,"PropertyName":"value","Value":"[datafield:puanlamatanim]"},{"Id":393810,"PropertyName":"value","Value":"Son Sevkiyat :"},{"Id":908934,"PropertyName":"value","Value":"[datafield:sonsevkiyat]"},{"Id":992434,"PropertyName":"value","Value":"Depo Durumu :"},{"Id":517248,"PropertyName":"value","Value":"[datafield:depobilgi]"},{"Id":610962,"PropertyName":"value","Value":"Bilgi :"},{"Id":431728,"PropertyName":"value","Value":"[datafield:digeryorum]"},{"Id":990087,"PropertyName":"value","Value":"Tarih :"},{"Id":570800,"PropertyName":"value","Value":"[datafield:cikissaatizamani]"},{"Id":92144,"PropertyName":"label","Value":"Excel çıktı al"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);
		this.sahaziyaretlistesi_142089_value_kuikaDatePickerRef = React.createRef();
		this.sahaziyaretlistesi_93316_value_kuikaTableRef = React.createRef();
		this.sahaziyaretlistesi_609608_value_kuikaPaginationRef = React.createRef();

        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        SahaZiyaretFormuTableSelectAll: [],
	        SahaZiyaretFormuTableCount: [],
	        NAVIGATE: "",
	        ExportExcel: "",

            isMasterPageInitLoaded: false,
  isMasterPageRecurringLoaded: false,
        }

        this.CheckPageAuthorization(this.state);

        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("sahaziyaretlistesi", "mainframe_screen");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (false){
            await this.SahaZiyaretListesiPageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("sahaziyaretlistesi", "mainframe_screen");
    }

    componentWillUnmount() {
        
        
        
    }

    CheckPageAuthorization = async (state) => {
KuikaAppManager.showSpinner(this);
if (_.isEmpty(window.userInfo)) {
  await UserService.GetUserInfoForce();
}
const permittedRoleNames : string[] = ["Saha Personeli","Admin","Manager",];
const userInfo = window.userInfo as IUserInfoDto;
const userRoleNames = userInfo.roles.map(role => role.name);
if (!permittedRoleNames.some(roleName => userRoleNames.includes(roleName))) {
await ReactSystemFunctions.goBack(this);
KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
state.isPageVisible = false;
KuikaAppManager.hideSpinner(this);
return;
}
state.isPageVisible = true;
KuikaAppManager.hideSpinner(this);
}


        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("sahaziyaretlistesi", "mainframe_screen"    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.SahaZiyaretListesiPageInit();
		}

        		if (prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded && this.state.isMasterPageInitLoaded === true) {await this.SahaZiyaretListesiPageInit();}

	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

        setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  }

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  }

    SahaZiyaretListesiPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

        let localVar = {
			startDate_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sahaziyaretlistesi_142089_value", "startValue", "", "", "")), "Date"),
			endDate_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sahaziyaretlistesi_142089_value", "endValue", "", "", "")), "Date"),
			currentpage_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sahaziyaretlistesi_609608_value", "current", "", "", "")), "number"),
			pagesize_0: ReactSystemFunctions.convertToTypeByName(20, "number")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "SahaZiyaretListesi/SahaZiyaretListesiPageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.SahaZiyaretFormuTableSelectAll = result?.data.sahaZiyaretFormuTableSelectAll;
		
		stateVars.SahaZiyaretFormuTableCount = result?.data.sahaZiyaretFormuTableCount;
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.SahaZiyaretListesiPageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	SahaZiyaretListesiPageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			
			stateVars.dataSource_93316 = this.state.SahaZiyaretFormuTableSelectAll;
			formVars.sahaziyaretlistesi_609608_total = ReactSystemFunctions.value(this, this.state.SahaZiyaretFormuTableCount?.length > 0 ? this.state.SahaZiyaretFormuTableCount[0]?.count : null);


	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




    SahaZiyaretListesiComponent_142089_onChange = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();


        let localVar = {
				startDate_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sahaziyaretlistesi_142089_value", "startValue", "", "", "")), "Date"),
				endDate_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sahaziyaretlistesi_142089_value", "endValue", "", "", "")), "Date"),
				currentpage_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sahaziyaretlistesi_609608_value", "current", "", "", "")), "number"),
				pagesize_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sahaziyaretlistesi_609608_value", "pageSize", "", "", "")), "number")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "SahaZiyaretListesi/SahaZiyaretListesiComponent_142089_onChange", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SahaZiyaretFormuTableSelectAll = result?.data.sahaZiyaretFormuTableSelectAll;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.SahaZiyaretListesiComponent_142089_onChange1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		SahaZiyaretListesiComponent_142089_onChange1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				
				stateVars.dataSource_93316 = this.state.SahaZiyaretFormuTableSelectAll;

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




		SahaZiyaretListesiComponent_641826_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("SahaZiyaretFormu", "verigetir", ReactSystemFunctions.value(this, "sahaziyaretlistesi_93316_value", "id"));

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "SahaZiyaretListesi", "SahaZiyaretFormu", "", "currentPage", null, null, null, null, null, null, true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


    SahaZiyaretListesiComponent_609608_onChange = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				startDate_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sahaziyaretlistesi_142089_value", "startValue", "", "", "")), "Date"),
				endDate_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sahaziyaretlistesi_142089_value", "endValue", "", "", "")), "Date"),
				currentpage_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sahaziyaretlistesi_609608_value", "current", "", "", "")), "number"),
				pagesize_0: ReactSystemFunctions.convertToTypeByName(5, "number")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "SahaZiyaretListesi/SahaZiyaretListesiComponent_609608_onChange", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SahaZiyaretFormuTableSelectAll = result?.data.sahaZiyaretFormuTableSelectAll;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.SahaZiyaretListesiComponent_609608_onChange1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		SahaZiyaretListesiComponent_609608_onChange1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				
				stateVars.dataSource_93316 = this.state.SahaZiyaretFormuTableSelectAll;

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    SahaZiyaretListesiComponent_92144_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				value_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, this.state.SahaZiyaretFormuTableSelectAll), "any"),
				header_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				footer_0: ReactSystemFunctions.convertToTypeByName("", "string")
        }

		KuikaAppManager.showSpinner(this);

            stateVars.Confirm = await Swal.fire({
                title: ReactSystemFunctions.translateCustomActions(this.ml,"a654e234_2eaa_036e_097c_14a98607d328_confirmation",this.defaultML,"İndiriliyor..." ),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: localStorage.getItem("ml") === 'tr_TR' ? 'Onayla' : localStorage.getItem("ml") === 'fr_FR' ? 'Confirmer' : 'Confirm',
                cancelButtonText: localStorage.getItem("ml") === 'tr_TR' ? 'Vazgeç' : localStorage.getItem("ml") === 'fr_FR' ? 'Annuler' : 'Cancel',
                customClass: {
                  title: 'swal2-font-poppins',
                }
            })

            if (stateVars.Confirm.isConfirmed)
            {
                		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "SahaZiyaretListesi/SahaZiyaretListesiComponent_92144_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.ExportExcel = result?.data.exportExcel;
			ReactSystemFunctions.downloadFile(result?.data?.exportExcel);
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);

            }


        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }





    async callCallbackFunction(diId) {
        let isErrorOccurred = false;
		let pageInitCallerNavDiIDs = [] as number[];
		if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
			await this.SahaZiyaretListesiPageInit();
		}

    }
}
