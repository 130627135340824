import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IKullaniciListe_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IKullaniciListe_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	KuserSelectAllUser: any[];
	KuserSelectAllUser_dummy: any[];
	toplamkuser: any[];
	toplamkuser_dummy: any[];
	NAVIGATE: any;
	NAVIGATE_dummy: any;
	DisableUser: any;
	DisableUser_dummy: any;
	EnableUser: any;
	EnableUser_dummy: any;
	TriggerEvent: string;
	TriggerEvent_dummy: string;
	isCompkullaniciliste_69396AuthorizationVisible: 'visible' | 'hidden';

isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
}

export class KullaniciListe_ScreenBase extends React.PureComponent<IKullaniciListe_ScreenProps, any> {
	kullaniciliste_69396_value_kuikaPaginationRef: React.RefObject<any>;
	kullaniciliste_93316_value_kuikaTableRef: React.RefObject<any>;
    ml=[{"Id":"c27bb49b-e84b-2132-b611-3806f3391b29","Name":"tr_TR","ShortName":"tr","IsDefault":true,"LanguagePhrases":[]},{"Id":"06d2c229-146e-4155-8c55-a1684b084d7f","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"65b75b72-8037-48f4-ab32-e7a9019cd496","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":287831,"PropertyName":"value","Value":"KULLANICILAR"},{"Id":59539,"PropertyName":"label","Value":"Yeni Kullanıcı"},{"Id":422013,"PropertyName":"value","Value":"KULLANICI LİSTESİ"},{"Id":93316,"PropertyName":"nodatafoundmessage","Value":"No data found."},{"Id":979531,"PropertyName":"title","Value":"Adı"},{"Id":209772,"PropertyName":"value","Value":"[datafield:adi]"},{"Id":317990,"PropertyName":"title","Value":"Kullanıcı Adı"},{"Id":183066,"PropertyName":"value","Value":"[datafield:username]"},{"Id":354462,"PropertyName":"title","Value":"Erişim Durumu"},{"Id":383184,"PropertyName":"value","Value":"[datafield:status_description]"},{"Id":286067,"PropertyName":"title","Value":"Düzenle"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);
		this.kullaniciliste_69396_value_kuikaPaginationRef = React.createRef();
		this.kullaniciliste_93316_value_kuikaTableRef = React.createRef();

        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        KuserSelectAllUser: [],
	        toplamkuser: [],
	        NAVIGATE: "",
	        DisableUser: "",
	        EnableUser: "",
	        TriggerEvent: "",
	        isCompkullaniciliste_69396AuthorizationVisible: 'visible',

            isMasterPageInitLoaded: false,
  isMasterPageRecurringLoaded: false,
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("kullaniciliste", "mainframe_screen");
            return;
        }
        
        
        if (false){
            await this.KullaniciListePageInit();
        }
        ReactSystemFunctions.hideIsNotAuthorized(this, "isCompkullaniciliste_69396AuthorizationVisible", ["2f1fb53b-d0eb-f20f-195b-0368c6884c30","35d35d5a-ccde-80e6-c61f-f71c537e94d9","Admin","Manager"]);

        
        
        KuikaAppManager.calculateAndSetBodyHeight("kullaniciliste", "mainframe_screen");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("kullaniciliste", "mainframe_screen"    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.KullaniciListePageInit();
		}

        		if (prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded && this.state.isMasterPageInitLoaded === true) {await this.KullaniciListePageInit();}

	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

        setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  }

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  }

    KullaniciListePageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

        let localVar = {
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "KullaniciListe/KullaniciListePageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.KuserSelectAllUser = result?.data.kuserSelectAllUser;
		
		stateVars.toplamkuser = result?.data.toplamkuser;
		formVars.kullaniciliste_983439_value = ReactSystemFunctions.toString(this, stateVars.toplamkuser?.length > 0 ? stateVars.toplamkuser[0]?.toplam_kullanici : null);
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.KullaniciListePageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	KullaniciListePageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			formVars.kullaniciliste_983439_value = ReactSystemFunctions.toString(this, this.state.toplamkuser?.length > 0 ? this.state.toplamkuser[0]?.toplam_kullanici : null);

			formVars.kullaniciliste_69396_total = ReactSystemFunctions.value(this, this.state.toplamkuser?.length > 0 ? this.state.toplamkuser[this.state.toplamkuser.length - 1].toplam_kullanici : null);
formVars.kullaniciliste_69396_pageSize = ReactSystemFunctions.value(this, this.state.toplamkuser?.length > 0 ? this.state.toplamkuser[0]?.toplam_kullanici : null);

			formVars.kullaniciliste_69396_total = ReactSystemFunctions.value(this, this.state.toplamkuser?.length > 0 ? this.state.toplamkuser[this.state.toplamkuser.length - 1].toplam_kullanici : null);
formVars.kullaniciliste_69396_pageSize = ReactSystemFunctions.value(this, this.state.toplamkuser?.length > 0 ? this.state.toplamkuser[0]?.toplam_kullanici : null);

			
			stateVars.dataSource_93316 = this.state.KuserSelectAllUser;

	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




		KullaniciListeComponent_59539_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("KullaniciTanim", "UserId", Guid.create().toString());

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "KullaniciListe", "KullaniciTanim", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "489968", null, "right", null, "350px", "100vh", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


    KullaniciListeComponent_93316_onConfigChange = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "KullaniciListe/KullaniciListeComponent_93316_onConfigChange", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.KuserSelectAllUser = result?.data.kuserSelectAllUser;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.KullaniciListeComponent_93316_onConfigChange1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		KullaniciListeComponent_93316_onConfigChange1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				
				stateVars.dataSource_93316 = this.state.KuserSelectAllUser;

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    KullaniciListeComponent_93316_onRowEditFinished = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "KullaniciListe/KullaniciListeComponent_93316_onRowEditFinished", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.KuserSelectAllUser = result?.data.kuserSelectAllUser;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.KullaniciListeComponent_93316_onRowEditFinished1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		KullaniciListeComponent_93316_onRowEditFinished1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				
				stateVars.dataSource_93316 = this.state.KuserSelectAllUser;

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




		KullaniciListeComponent_498925_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("KullaniciTanim", "UserId", ReactSystemFunctions.value(this, "kullaniciliste_93316_value", "id"));

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "KullaniciListe", "KullaniciTanim", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "583004", null, "right", null, "350px", "100vh", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		KullaniciListeComponent_689015_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
if ((ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "kullaniciliste_93316_value", "status_description"), "AKTIF"))) {
            KuikaAppManager.showSpinner(this);

				stateVars.DisableUser = await ReactSystemFunctions.disableUser(ReactSystemFunctions.value(this, "kullaniciliste_93316_value", "userName"));

		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.KullaniciListeComponent_689015_onClick1_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.KullaniciListeComponent_689015_onClick1_();
                  resolve();
                });
          })
        }

            
            return isErrorOccurred;
        }
		KullaniciListeComponent_689015_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
if ((ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "kullaniciliste_93316_value", "status_description"), "PASIF"))) {
            
				stateVars.EnableUser = await ReactSystemFunctions.enableUser(ReactSystemFunctions.value(this, "kullaniciliste_93316_value", "userName"));

		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.KullaniciListeComponent_689015_onClick2_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.KullaniciListeComponent_689015_onClick2_();
                  resolve();
                });
          })
        }

            
            return isErrorOccurred;
        }
		KullaniciListeComponent_689015_onClick2_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				if(await this.KullaniciListeComponent_93316_onConfigChange()) return true;
				
				if(await this.KullaniciListeComponent_93316_onRowEditFinished()) return true;
				

		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.KullaniciListeComponent_689015_onClick3_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
    KullaniciListeComponent_689015_onClick3_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
        }

		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "KullaniciListe/KullaniciListeComponent_689015_onClick3_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.KuserSelectAllUser = result?.data.kuserSelectAllUser;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.KullaniciListeComponent_689015_onClick4_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		KullaniciListeComponent_689015_onClick4_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				
				stateVars.dataSource_93316 = this.state.KuserSelectAllUser;

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }









    async callCallbackFunction(diId) {
        let isErrorOccurred = false;
		let pageInitCallerNavDiIDs = [489968, 583004] as number[];
		if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
			await this.KullaniciListePageInit();
		}

    }
}
