import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IMigrosVeriGirisi_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IMigrosVeriGirisi_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	Satislarselect: any[];
	Satislarselect_dummy: any[];
	UploadFile: any;
	UploadFile_dummy: any;
	ImportExcelBase64: string;
	ImportExcelBase64_dummy: string;
	Notify: boolean;
	Notify_dummy: boolean;
	tekrarSilici: number;
	tekrarSilici_dummy: number;

isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
}

export class MigrosVeriGirisi_ScreenBase extends React.PureComponent<IMigrosVeriGirisi_ScreenProps, any> {
	migrosverigirisi_213037_value_kuikaTableRef: React.RefObject<any>;
	migrosverigirisi_252687_value_kuikaDateRef: React.RefObject<any>;
    ml=[{"Id":"c27bb49b-e84b-2132-b611-3806f3391b29","Name":"tr_TR","ShortName":"tr","IsDefault":true,"LanguagePhrases":[]},{"Id":"06d2c229-146e-4155-8c55-a1684b084d7f","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"1cb92339-15d7-49a0-ac47-1829a59d3ea3","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":42720,"PropertyName":"label","Value":"VERİ GİR"},{"Id":119625,"PropertyName":"value","Value":"MİGROS B2B GÜNLÜK SATIŞ VERİSİ "},{"Id":903997,"PropertyName":"title","Value":"Mağaza Adı"},{"Id":288825,"PropertyName":"value","Value":"[datafield:magaza]"},{"Id":421365,"PropertyName":"placeholder","Value":"Type here..."},{"Id":837680,"PropertyName":"title","Value":"Mağaza Kodu"},{"Id":611562,"PropertyName":"value","Value":"[datafield:magazakodu]"},{"Id":851776,"PropertyName":"placeholder","Value":"15"},{"Id":737509,"PropertyName":"title","Value":"Ürün Adı"},{"Id":54045,"PropertyName":"value","Value":"[datafield:urunadi]"},{"Id":511274,"PropertyName":"placeholder","Value":"Type here..."},{"Id":789451,"PropertyName":"title","Value":"Adet"},{"Id":64586,"PropertyName":"value","Value":"[datafield:adet]"},{"Id":450523,"PropertyName":"placeholder","Value":"15"},{"Id":490068,"PropertyName":"title","Value":"Tutar"},{"Id":114763,"PropertyName":"value","Value":"[datafield:tutar]"},{"Id":941639,"PropertyName":"placeholder","Value":"15"},{"Id":798406,"PropertyName":"title","Value":"Tarih"},{"Id":93189,"PropertyName":"value","Value":"[datafield:tarih]"},{"Id":252687,"PropertyName":"placeholder","Value":"Tarih seç..."}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);
		this.migrosverigirisi_213037_value_kuikaTableRef = React.createRef();
		this.migrosverigirisi_252687_value_kuikaDateRef = React.createRef();

        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        Satislarselect: [],
	        UploadFile: "",
	        ImportExcelBase64: "",
	        Notify: false,
	        tekrarSilici: 0,

            isMasterPageInitLoaded: false,
  isMasterPageRecurringLoaded: false,
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("migrosverigirisi", "mainframe_screen");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (false){
            await this.MigrosVeriGirisiPageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("migrosverigirisi", "mainframe_screen");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("migrosverigirisi", "mainframe_screen"    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.MigrosVeriGirisiPageInit();
		}

        		if (prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded && this.state.isMasterPageInitLoaded === true) {await this.MigrosVeriGirisiPageInit();}

	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

        setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  }

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  }

    MigrosVeriGirisiPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

        let localVar = {
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MigrosVeriGirisi/MigrosVeriGirisiPageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.Satislarselect = result?.data.satislarselect;
		
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MigrosVeriGirisiPageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	MigrosVeriGirisiPageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			
			stateVars.dataSource_213037 = this.state.Satislarselect;

	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




		MigrosVeriGirisiComponent_42720_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.UploadFile = (await ReactSystemFunctions.uploadFileWithInfo() as any).data;

		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MigrosVeriGirisiComponent_42720_onClick1_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
    MigrosVeriGirisiComponent_42720_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				excelBase64_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), "string"),
				tableName_0: ReactSystemFunctions.convertToTypeByName("satislarTable", "string"),
				primaryKeyName_0: ReactSystemFunctions.convertToTypeByName("", "string")
        }

		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MigrosVeriGirisi/MigrosVeriGirisiComponent_42720_onClick1_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.ImportExcelBase64 = result?.data.importExcelBase64;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MigrosVeriGirisiComponent_42720_onClick2_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		MigrosVeriGirisiComponent_42720_onClick2_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				stateVars.Notify = await ReactSystemFunctions.notify(this, ReactSystemFunctions.translateCustomActions(this.ml,"e4107be8_7c15_c9c5_f4c7_a9caadfcfa6c_notify",this.defaultML,"import edildi düzenleniyor :)" ), "success", "bottom-right", 2, "", "", 0);

		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MigrosVeriGirisiComponent_42720_onClick3_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
    MigrosVeriGirisiComponent_42720_onClick3_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
        }

		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MigrosVeriGirisi/MigrosVeriGirisiComponent_42720_onClick3_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.tekrarSilici = result?.data.tekrarSilici;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MigrosVeriGirisiComponent_42720_onClick4_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		MigrosVeriGirisiComponent_42720_onClick4_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				stateVars.Notify = await ReactSystemFunctions.notify(this, ReactSystemFunctions.translateCustomActions(this.ml,"a9c1641e_b348_b7ac_9bdf_fefae5b1d841_notify",this.defaultML,"Başarılı :)" ), "success", "bottom-right", 2, "", "", 0);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }










    async callCallbackFunction(diId) {
        let isErrorOccurred = false;

    }
}
