import React from "react";
import { BrowserRouter, HashRouter, Switch } from "react-router-dom";
import { Redirect } from "../auth/redirect";
import { NoMatch404 } from "../components/common/no-match-404";
import { Dashboard, ForgotPassword, Login, ResetPassword } from "../components/default_screens/index";
import { PrivateRoute } from "./private-route";
import { Route } from "./route";
import { SahaPersSatisDagilim_Screen } from "../screens/sahaperssatisdagilim/sahaperssatisdagilim";
import { MagazaDuzenle_Screen } from "../screens/magazaduzenle/magazaduzenle";
import { Authentication_Forgot_Password_Screen } from "../screens/authentication_forgot_password/authentication_forgot_password";
import { Authentication_User_Template_Screen } from "../screens/authentication_user_template/authentication_user_template";
import { MainFrame_Screen } from "../screens/mainframe/mainframe";
import { MagazaTanim_Screen } from "../screens/magazatanim/magazatanim";
import { Authentication_Reset_Password_Screen } from "../screens/authentication_reset_password/authentication_reset_password";
import { AddStore_Screen } from "../screens/addstore/addstore";
import { Signin_Screen } from "../screens/signin/signin";
import { MagazaBilgiEkrani_Screen } from "../screens/magazabilgiekrani/magazabilgiekrani";
import { TableDeneme_Screen } from "../screens/tabledeneme/tabledeneme";
import { MagazaListe_Screen } from "../screens/magazaliste/magazaliste";
import { Magazalts_Screen } from "../screens/magazalts/magazalts";
import { MagazaUrunSatisTanim_Screen } from "../screens/magazaurunsatistanim/magazaurunsatistanim";
import { SahaGorevlisiMain_Screen } from "../screens/sahagorevlisimain/sahagorevlisimain";
import { MigrosVeriGirisi_Screen } from "../screens/migrosverigirisi/migrosverigirisi";
import { LeftMenu_Screen } from "../screens/leftmenu/leftmenu";
import { SahaZiyaretFormucopy_Screen } from "../screens/sahaziyaretformucopy/sahaziyaretformucopy";
import { SahaZiyaretFormu2_Screen } from "../screens/sahaziyaretformu2/sahaziyaretformu2";
import { TRSatisSezonsallikGENEL_Screen } from "../screens/trsatissezonsallikgenel/trsatissezonsallikgenel";
import { TabloekraniDagilm_Screen } from "../screens/tabloekranidagilm/tabloekranidagilm";
import { UrunSatisTRGENEL_Screen } from "../screens/urunsatistrgenel/urunsatistrgenel";
import { Authentication_Verification_Code_Screen } from "../screens/authentication_verification_code/authentication_verification_code";
import { HomePage_Screen } from "../screens/homepage/homepage";
import { KullaniciListe_Screen } from "../screens/kullaniciliste/kullaniciliste";
import { KullaniciTanim_Screen } from "../screens/kullanicitanim/kullanicitanim";
import { SahaZiyaretListesi_Screen } from "../screens/sahaziyaretlistesi/sahaziyaretlistesi";
import { GunlukZiyaretRotalari_Form_Screen } from "../screens/gunlukziyaretrotalari_form/gunlukziyaretrotalari_form";
import { MagazaUrunTavsiyeKutuTanim_Screen } from "../screens/magazauruntavsiyekututanim/magazauruntavsiyekututanim";
import { GunlukSatis_Screen } from "../screens/gunluksatis/gunluksatis";
import { UrunSatisGrupTanim_Screen } from "../screens/urunsatisgruptanim/urunsatisgruptanim";
import { UrunSatisGrupListe_Screen } from "../screens/urunsatisgrupliste/urunsatisgrupliste";
import { CevdetScreen_Screen } from "../screens/cevdetscreen/cevdetscreen";
import { Kur_Screen } from "../screens/kur/kur";
import { BolgeTRSatis_Screen } from "../screens/bolgetrsatis/bolgetrsatis";
import { VerificationCode_Screen } from "../screens/verificationcode/verificationcode";
import { Rut_Screen } from "../screens/rut/rut";
import { SahaPersMagazaInfo_Screen } from "../screens/sahapersmagazainfo/sahapersmagazainfo";
import { SahaZiyaretFormu_Screen } from "../screens/sahaziyaretformu/sahaziyaretformu";
import { RutYT_Screen } from "../screens/rutyt/rutyt";



import { IAppMode, IAuthType } from "../bootstrapper";
import { KuikaInfo } from "../components/default_screens/kuika-info";
import { MfeLogin } from "../components/mfe/mfe-login";
import { MsalLogin } from "../components/msal/msal-login";
import { MsalRedirect } from "../components/msal/msal-redirect";
import { WFMailInteraction } from "../screens/wf-mail-interaction";
import { KMainFunctions } from "../shared/utilty/main-functions";

const confJson = require("../shared/json/conf.json");

export interface IRouterState {}

export type IRouterType = "HashRouter" | "BrowserRouter";

export interface IRouterProps {
  authType: IAuthType;
  routerType?: IRouterType;
  external_access_token?: string;
  external_refresh_token?: string;
  external_user?: string;
  appMode?: IAppMode;
}

declare let window: any;
export class Router extends React.Component<IRouterProps, IRouterState> {
  timerId: any = null;

  componentDidMount = () => {
    window.onfocus = function () {
      if (window.kuika === undefined) {
        window.kuika = {};
      }
      window.kuika.isTabActive = true;
    };

    window.onblur = function () {
      if (window.kuika === undefined) {
        window.kuika = {};
      }
      window.kuika.isTabActive = false;
    };

    if (!window.styler_speed) {
      window.styler_speed = 20;
    } else if (window.styler_speed < 500) {
      window.styler_speed += 100;
    }

    if (window?.kuika?.activeKuikaComponent !== undefined) {
      return;
    }
    const runTimeout = () => {
      this.timerId = setTimeout(() => {
        const allFormItems: NodeListOf<HTMLElement> = document.querySelectorAll(".kFormItem");
        if (allFormItems) {
          allFormItems.forEach((formItem) => {
            if (formItem.getAttribute("data-display-affected") === "true") {
              return;
            }
            const antFormItemControlInputContent: HTMLElement = formItem.querySelector(
              ".ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content"
            );
            const antFormItemControlInput: HTMLElement = formItem.querySelector(
              ".ant-form-item-control .ant-form-item-control-input"
            );
            const antFormItemControl: HTMLElement = formItem.querySelector(".ant-form-item-control");

            if (
              antFormItemControlInputContent &&
              antFormItemControlInputContent.children &&
              antFormItemControlInputContent.children.length > 0
            ) {
              const formChild: HTMLElement = antFormItemControlInputContent.children[0] as HTMLElement;
              let calculatedDisplay: string = formChild.style.display;
              if (
                calculatedDisplay &&
                antFormItemControl &&
                antFormItemControlInput &&
                antFormItemControlInputContent
              ) {
                if (calculatedDisplay === "inline-flex" || calculatedDisplay === "inline-block") {
                  calculatedDisplay = "";
                  formItem.style.width = formChild.style.width ?? "auto";
                  formItem.style.display = "inline-block";
                  formItem.setAttribute("data-display-affected", "true");
                }
              }
            }
          });
        }
        this.componentDidMount();
      }, window.styler_speed);
    };

    runTimeout();

    setTimeout(() => {
      clearTimeout(this.timerId);
    }, 10000);
  };

  render() {
    const KuikaRouter = this.KuikaRouter();
    return (
      <>
        <KuikaRouter>
          <Switch key="Switch">
            <PrivateRoute exact path="/" component={HomePage_Screen} />
                        <PrivateRoute exact path="/sahaperssatisdagilim" component={SahaPersSatisDagilim_Screen} />
            <PrivateRoute exact path="/magazaduzenle" component={MagazaDuzenle_Screen} />
            <Route exact path="/authentication_forgot_password" component={Authentication_Forgot_Password_Screen} />
            <Route exact path="/authentication_user_template" component={Authentication_User_Template_Screen} />
            <PrivateRoute exact path="/magazatanim" component={MagazaTanim_Screen} />
            <Route exact path="/authentication_reset_password" component={Authentication_Reset_Password_Screen} />
            <PrivateRoute exact path="/addstore" component={AddStore_Screen} />
            <Route exact path="/signin" component={this.LoginComponent()} />
            <PrivateRoute exact path="/magazabilgiekrani" component={MagazaBilgiEkrani_Screen} />
            <PrivateRoute exact path="/tabledeneme" component={TableDeneme_Screen} />
            <PrivateRoute exact path="/magazaliste" component={MagazaListe_Screen} />
            <PrivateRoute exact path="/magazalts" component={Magazalts_Screen} />
            <PrivateRoute exact path="/magazaurunsatistanim" component={MagazaUrunSatisTanim_Screen} />
            <PrivateRoute exact path="/sahagorevlisimain" component={SahaGorevlisiMain_Screen} />
            <PrivateRoute exact path="/migrosverigirisi" component={MigrosVeriGirisi_Screen} />
            <PrivateRoute exact path="/leftmenu" component={LeftMenu_Screen} />
            <Route exact path="/sahaziyaretformucopy" component={SahaZiyaretFormucopy_Screen} />
            <Route exact path="/sahaziyaretformu2" component={SahaZiyaretFormu2_Screen} />
            <PrivateRoute exact path="/trsatissezonsallikgenel" component={TRSatisSezonsallikGENEL_Screen} />
            <PrivateRoute exact path="/tabloekranidagilm" component={TabloekraniDagilm_Screen} />
            <PrivateRoute exact path="/urunsatistrgenel" component={UrunSatisTRGENEL_Screen} />
            <Route exact path="/authentication_verification_code" component={Authentication_Verification_Code_Screen} />
            <PrivateRoute exact path="/homepage" component={HomePage_Screen} />
            <Route exact path="/kullaniciliste" component={KullaniciListe_Screen} />
            <Route exact path="/kullanicitanim" component={KullaniciTanim_Screen} />
            <PrivateRoute exact path="/sahaziyaretlistesi" component={SahaZiyaretListesi_Screen} />
            <PrivateRoute exact path="/gunlukziyaretrotalari_form" component={GunlukZiyaretRotalari_Form_Screen} />
            <PrivateRoute exact path="/magazauruntavsiyekututanim" component={MagazaUrunTavsiyeKutuTanim_Screen} />
            <PrivateRoute exact path="/gunluksatis" component={GunlukSatis_Screen} />
            <PrivateRoute exact path="/urunsatisgruptanim" component={UrunSatisGrupTanim_Screen} />
            <PrivateRoute exact path="/urunsatisgrupliste" component={UrunSatisGrupListe_Screen} />
            <PrivateRoute exact path="/cevdetscreen" component={CevdetScreen_Screen} />
            <PrivateRoute exact path="/kur" component={Kur_Screen} />
            <PrivateRoute exact path="/bolgetrsatis" component={BolgeTRSatis_Screen} />
            <Route exact path="/verificationcode" component={VerificationCode_Screen} />
            <PrivateRoute exact path="/rut" component={Rut_Screen} />
            <PrivateRoute exact path="/sahapersmagazainfo" component={SahaPersMagazaInfo_Screen} />
            <Route exact path="/sahaziyaretformu" component={SahaZiyaretFormu_Screen} />
            <PrivateRoute exact path="/rutyt" component={RutYT_Screen} />

            <Route path="/redirect" component={Redirect} />
            <Route path={"/login"} component={this.LoginComponent()} />
            
            
            <Route exact path="/forgotpassword" component={ForgotPassword} />
            <Route exact path="/resetpassword" component={ResetPassword} />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <Route exact path="/kuikainfo" component={KuikaInfo} />
            <Route exact path="/msalredirect" component={MsalRedirect} />
            <Route exact path="/wfmailinteraction" component={WFMailInteraction} />
            <Route path="*" component={NoMatch404} />
          </Switch>
        </KuikaRouter>
      </>
    );
  }

  KuikaRouter = () => {
    // Web component case
    let isWebComponentApp: any;
    isWebComponentApp = this.props.appMode === "WebComponent";
    if (
      confJson?.domainName !== undefined &&
      confJson?.applicationName !== undefined &&
      confJson?.applicationName !== "" &&
      window.kuika !== undefined &&
      window.kuika?.apps !== undefined &&
      window.kuika?.apps.length !== undefined &&
      window.kuika?.apps.length > 0
    ) {
      window.kuika?.apps.forEach((app: any) => {
        if (app.appName === confJson.applicationName && app.workspace === confJson.domainName) {
          isWebComponentApp = true;
        }
      });
    }
    if (window.kuika === undefined) {
      window.kuika = {};
    }
    window.kuika.isWebComponentApp = isWebComponentApp;

    if (isWebComponentApp === true || this.props.routerType === "HashRouter") {
      return (props: any) => {
        return <HashRouter key="HashRouter">{props.children}</HashRouter>;
      };
    }
    return (props: any) => {
      if (KMainFunctions.virtualDirectoryURL === "auto" || window?.location?.protocol === "file:") {
        return <BrowserRouter key="BrowserRouter">{props.children}</BrowserRouter>;
      }
      return (
        <BrowserRouter basename={KMainFunctions.virtualDirectoryURL} key="BrowserRouter">
          {props.children}
        </BrowserRouter>
      );
    };
  };

  LoginComponent = () => {
    if (this.props.authType === "MSAL") {
      return MsalLogin;
    }

    const { external_access_token } = this.props;
    const { external_refresh_token } = this.props;
    const { external_user } = this.props;
    if (
      external_access_token !== null &&
      external_access_token !== undefined &&
      external_access_token !== "" &&
      external_refresh_token !== null &&
      external_refresh_token !== undefined &&
      external_refresh_token !== ""
    ) {
      return (props: any) => {
        return (
          <MfeLogin
            external_user={external_user}
            external_access_token={external_access_token}
            external_refresh_token={external_refresh_token}
          ></MfeLogin>
        );
      };
    }

    if (this.props.authType === "Kuika") {
      return Signin_Screen;
    }
    return <></>;
  };
}
